import { default as index20RePqN4taMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/auth/index.vue?macro=true";
import { default as indexeC4tpH6lymMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as _91id_93WwetL8PHkCMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/categories/[id].vue?macro=true";
import { default as indexdce3Bg05oBMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/categories/index.vue?macro=true";
import { default as _91id_93VoQwfUOxBDMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/costCenters/[id].vue?macro=true";
import { default as indexA5ljVh3aYbMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/costCenters/index.vue?macro=true";
import { default as _91id_93NQxPhoRVUTMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/devices/[id].vue?macro=true";
import { default as indexAjvRBKdzbsMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/devices/index.vue?macro=true";
import { default as indexrTeKXIqFp9Meta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/mailTemplates/index.vue?macro=true";
import { default as baseDatapy5nf9rRwUMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData.vue?macro=true";
import { default as index65x3cLwpbZMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/index.vue?macro=true";
import { default as orderBaseDataQhXGfKcgjCMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/orders/[id]/orderBaseData.vue?macro=true";
import { default as orderDetailspfVHieUTYSMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/orders/[id]/orderDetails.vue?macro=true";
import { default as orderHistoryoHYcOatii3Meta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/orders/[id]/orderHistory.vue?macro=true";
import { default as _91id_93eBtavCLd8oMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/orders/[id].vue?macro=true";
import { default as index0Q7I8nguooMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/orders/index.vue?macro=true";
import { default as organisationBaseDatakcQNhU13ndMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93huayag46hbMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/organisations/[id].vue?macro=true";
import { default as indexuJDE1WNQ4qMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/organisations/index.vue?macro=true";
import { default as organisations4il0ZEYaMEMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/organisations.vue?macro=true";
import { default as dungeonNv4ZSGGiEkMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/secret/index/dungeon.vue?macro=true";
import { default as inputsH7PNdNMl3EMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/secret/index/inputs.vue?macro=true";
import { default as indexN4JrJ5klixMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/secret/index.vue?macro=true";
import { default as languagezx66pFh8pXMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/settings/language.vue?macro=true";
import { default as _91id_93by6aZ19UdCMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as index8eKuaGJ163Meta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as _91_46_46_46slug_93gzZKpFKdfPMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as indexMort0RMRV3Meta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as settingsx2T1Od8aC0Meta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/settings.vue?macro=true";
import { default as userActivitiesseIf0HlgNQMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataCAYm4ngueKMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationsNFVJtXxwfWMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_93GWXAMEG5WAMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/users/[id].vue?macro=true";
import { default as index8qDxRJdNN2Meta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/users/index.vue?macro=true";
import { default as usersAEKGoNU8kcMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93IivKFzBwQtMeta } from "/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: index20RePqN4taMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexeC4tpH6lymMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData",
    path: "/baseData",
    meta: baseDatapy5nf9rRwUMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData.vue").then(m => m.default || m),
    children: [
  {
    name: "baseData-categories-id",
    path: "categories/:id()",
    meta: _91id_93WwetL8PHkCMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/categories/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-categories",
    path: "categories",
    meta: indexdce3Bg05oBMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-costCenters-id",
    path: "costCenters/:id()",
    meta: _91id_93VoQwfUOxBDMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/costCenters/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-costCenters",
    path: "costCenters",
    meta: indexA5ljVh3aYbMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/costCenters/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-devices-id",
    path: "devices/:id()",
    meta: _91id_93NQxPhoRVUTMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/devices/[id].vue").then(m => m.default || m)
  },
  {
    name: "baseData-devices",
    path: "devices",
    meta: indexAjvRBKdzbsMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "baseData-mailTemplates",
    path: "mailTemplates",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/baseData/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    meta: _91id_93eBtavCLd8oMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "orders-id-orderBaseData",
    path: "orderBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/orders/[id]/orderBaseData.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderDetails",
    path: "orderDetails",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/orders/[id]/orderDetails.vue").then(m => m.default || m)
  },
  {
    name: "orders-id-orderHistory",
    path: "orderHistory",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/orders/[id]/orderHistory.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "orders",
    path: "/orders",
    meta: index0Q7I8nguooMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: organisations4il0ZEYaMEMeta?.name,
    path: "/organisations",
    meta: organisations4il0ZEYaMEMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_93huayag46hbMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: indexuJDE1WNQ4qMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/secret/index.vue").then(m => m.default || m),
    children: [
  {
    name: "secret-index-dungeon",
    path: "dungeon",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/secret/index/dungeon.vue").then(m => m.default || m)
  },
  {
    name: "secret-index-inputs",
    path: "inputs",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/secret/index/inputs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsx2T1Od8aC0Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    meta: _91id_93by6aZ19UdCMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: index8eKuaGJ163Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93gzZKpFKdfPMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "mailTemplates",
    meta: indexMort0RMRV3Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersAEKGoNU8kcMeta?.name,
    path: "/users",
    meta: usersAEKGoNU8kcMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_93GWXAMEG5WAMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: index8qDxRJdNN2Meta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93IivKFzBwQtMeta || {},
    component: () => import("/home/forge/bema.farbcode.net/releases/20241015130023/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]