import table from './table'

export default {
    data: 'Daten',
    details: 'Details',
    history: 'Historie',
    statusTree: 'Statusbaum',
    detailDocument: 'Detaildokument',
    name: 'Bezeichnung',
    description: 'Beschreibung',
    category: 'Kategorie',
    searchCategory: 'Kategorie suchen',
    costCenter: 'Kostenstelle',
    searchCostCenter: 'Kostenstelle suchen',
    costCenterManager: 'Kostenstellenverantwortlicher',
    quantity: 'Menge',
    unit: 'Einheit',
    offer: 'Angebot',
    searchOffer: 'Angebot suchen',
    date: 'Datum',
    conditions: 'Bedingungen',
    totalPrice: 'Gesamtpreis',
    orderId: 'Beschaffung #{id}',

    orderTypes: {
        order: 'Beschaffung',
        device: 'Standardgerät',
        special: 'Sonderbestellung',
        express: 'Eilbestellung',
    },
    
    request: {
        title: 'Beschaffungsanfrage',
        recipient: 'Empfänger',
        desiredDeliveryDate: 'Gewünschtes Lieferdatum',
        visibleUntil: 'Sichtbar bis',
        details: 'Details (Dokumentupload)',
    },
    
    order: {
        title: 'Beschaffungsantrag',
        recipient: 'Empfänger',
        orderType: 'Art der Beschaffung',
        orderTypes: {
            firstOrder: 'Erstbeschaffung',
            replacementOrder: 'Ersatzbeschaffung',
            additionOrder: 'Ergänzungsbeschaffung',
            serviceOrder: 'Wartung',
            consumptionOrder: 'Verbrauchsmaterial',
        },
        reason: 'Grund für Auftragserteilung',
        reasons: {
            cheapestOffer: 'Günstigstes Angebot',
            contractPartner: 'Rahmenvertragspartner',
            onlyProvider: 'Einzelanbieter',
            mostEconomicalOffer: 'Wirtschaftlichstes Angebot',
        },
        additionalReason: 'Zusatztext zur Entscheidung',
        followUpCosts: 'Fallen Folgekosten an?',
        followUpCostsType: 'Art der Folgekosten',
        annualCosts: 'Jährliche Kosten',
        funding: 'Finanzierung der Folgekosten aus Fachbereichsmitteln?',
        isSpaceAvailable: 'Raum verfügbar?',
        room: 'Raum',
        isStaffAvailable: 'Personal verfügbar?',
        staff: 'Zuständig für Bedienung',
        supportStaff: 'Wartung und technische Betreuung durch',
        additionalInfo: 'Zusatzinformationen',
        dangerousGoods: 'Handelt es sich um Gefahrengut?',
        structuralMeasures: 'Bauliche Maßnahmen für die Aufstellung des Gerätes',
        orderDetails: 'Bestelltext'
    },

    device: {
        title: 'Standardgerätebestellung',
        device: 'Standardgerät',
        searchDevice: 'Gerät suchen',
    },

    special: {
        title: 'Sonderbestellung',
        deliveryDate: 'Lieferdatum',
        orderReason: 'Begründung für Sonderbestellung',
        reasons: {
            reasonOne: 'Grund 1',
            other: 'Sonstiger Grund'
        },
        orderReasonText: 'Weitere Begründung',
        description: 'Leistungsbeschreibung'
    },

    express: {
        title: 'Eilbestellung',
        itemNumber: 'Artikelnummer',
        name: 'Artikelbezeichnung',
        unitPrice: 'Einzelpreis',
        transportCosts: 'Transportkosten',
        supplier: 'Lieferant',
        searchSupplier: 'Lieferant suchen',
        orderRemarks: 'Hinweise / Bemerkungen',
        expressDescription: 'Beschreibung',
    },

    status: {
        open: 'Gestartet',
        updated: 'Korrigiert',
        placeholder: '...',
        cost_center_approved: 'KST genehmigt',
        data_center_approved: 'RZ genehmigt',
        finance_approved: 'FZ genehmigt',
        rejected: 'Abgelehnt',  
        closed: 'Geschlossen',  
        canceled: 'Storniert'
    },

    units: {
        pcs: 'Stück',
        pack: 'Packung',
        carton: 'Karton',
        bag: 'Beutel',
        bundle: 'Bund',
        bottle: 'Flasche',
        canister: 'Kanister',
        liter: 'Liter',
        roll: 'Rolle',
        license: 'Lizenz',
        classroomLicense: 'Klassenraum-Lizenz',
        campusLicense: 'Campus-Lizenz',
        pack10: '10er-Pack',
        kg: 'kg',
        ml: 'ml',
        g: 'g',
        meter: 'Meter',
        m2: 'm²',
        m3: 'm³',
    },

    deleteOrder: 'Beschaffung löschen',
    deleteOrderText:
        'Möchtest du die Beschaffung wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
    deleteOrderSuccess: 'Beschaffung wurde erfolgreich gelöscht.',
    deleteOrderError: 'Die Beschaffung konnte nicht gelöscht werden.',

    confirmLeave: 'Sie haben nicht gespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?',

    table
}