export default {
    title: "Standard Geräte",
    name: "Bezeichnung",
    status: "Status",
    active: "Aktiv",
    net_price: "Preis (Netto)",
    priceTypes: {
        net: "Netto",
        gross: "Brutto"
    }
}